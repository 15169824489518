<template>
  <section id="platform-settings-transcoding">
    <!-- Transcoding configuration -->
    <b-overlay
      :show="!platformAllowTranscoding"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mb-2">
        <b-col>
          <h1>{{ $t("transcoding.transcode") }}</h1>
          <h5 class="text-primary">
            {{ $t("transcoding.transcodingData") }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="match-height mt-2">
        <b-col>
          <b-card>
            <div id="tabsTranscodingType">
              <b-tabs v-model="tabIndex">
                <!-- ElasticTranscoder AWS -->
                <settings-tabs-transcoding-type-aws :is-active="platform.transcodingType === 'AWS' ? true : false" />
                <!-- Bitmovin -->
                <settings-tabs-transcoding-type-bitmovin :is-active="platform.transcodingType === 'BTM' ? true : false" />
                <!-- Interactvty -->
                <settings-tabs-transcoding-type-interactvty
                  :is-active="platform.transcodingType === 'ITV' ? true : false"
                />
                <!-- <settings-tabs-bucket-type-s3 :isActive="platform.bucketType === 'AS3'? true: false"/> -->
              </b-tabs>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <template #overlay>
        <div class="text-center">
          <b-jumbotron
            bg-variant="transparent"
            border-variant="primary"
            :header="$t('desactValue')"
            class="text-center"
          >
            <b-row>
              <b-col>
                <h5>
                  {{ $t('activeShure') }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  no-body
                  @click="changeFunction(funcionalidad)"
                >
                  <b-avatar
                    class="cursor-pointer mb-2 mt-2"
                    size="70"
                    :variant="funcionalidad.value ? 'light-success' : 'light-danger'
                    "
                  >
                    <feather-icon
                      size="30"
                      :icon="funcionalidad.icon"
                    />
                  </b-avatar>
                  <h6 class="">
                    {{ funcionalidad.titulo }}
                    <feather-icon
                      v-b-tooltip.hover.top
                      :title="funcionalidad.descripcion"
                      icon="InfoIcon"
                    />
                  </h6>
                </div>
              </b-col>
            </b-row>
          </b-jumbotron>
        </div>
      </template>
    </b-overlay>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BAvatar,
  BJumbotron,
  BTabs,
  BOverlay,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import axios from '@axios'
import { messageError, showToast, setClientNode } from '@/store/functions'

import Ripple from 'vue-ripple-directive'
import SettingsTabsTranscodingTypeInteractvty from './components/transcoding/SettingsTabsTranscodingTypeInteractvty.vue'
import SettingsTabsTranscodingTypeAws from './components/transcoding/SettingsTabsTranscodingTypeAws.vue'
import SettingsTabsTranscodingTypeBitmovin from './components/transcoding/SettingsTabsTranscodingTypeBitmovin.vue'

export default {
  components: {
    BRow,
    BJumbotron,
    BCol,
    BCard,
    BTabs,
    BAvatar,
    SettingsTabsTranscodingTypeInteractvty,
    SettingsTabsTranscodingTypeAws,
    SettingsTabsTranscodingTypeBitmovin,
    BOverlay,

  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      transcodingTypes: ['AWS', 'BTM', 'ITV'],
      tabIndex: 0,
      platform: {
        transcodingType: '',
      },
      platformAllowTranscoding: false,
      funcionalidad: {},

    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  mounted() {
    const userData = getUserData()
    if (!userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    this.platformAllowTranscoding = userData.profile.client.isTranscoding

    const query = `
        query {
          client(id: "${userData.profile.client.id}"){
            id
            transcodingType
            isTranscoding
          }
        }
      `
    axios.post('', { query }).then(res => {
      this.funcionalidad = {
        value: res.isTranscoding,
        titulo: this.$t('transcoding.transcoding'),
        descripcion: this.$t('transcoding.transcodingAllowed'),
        icon: 'FilmIcon',
        id: 'isTranscoding',
      }
      this.tabIndex = this.transcodingTypes.indexOf(res.data.data.client.transcodingType)
    })
  },
  created() {
    const userData = getUserData()
    this.$http
      .post('', {
        query: `{
          client(id: "${userData.profile.client.id}") {
            id
            transcodingType
          }
        }`,
      })
      .then(r => {
        this.platform = r.data.data.client
      })
  },
  methods: {
    changeFunction() {
      const query = `
        mutation{
          updateClient(id:"${getUserData().profile.client.id}",input:{isTranscoding:true}){
            client{
              id
              name
              isShop
              hasProfiles
              isBuy
              isInteractivity
              isFavorite
              videotype
              isSecondScreenInteractivity
              isPushInteractivity
              isBlockInteractivity
              isElearningPlatform
              hasMyChannel
              isVirtualUser
              isClose
              isPromotionalCodes
              isDownload
              isParentalControl
              isAds
              hasRating
              hasAdSlides
              hasAdCuePoints
              contentRating
              productRating
              categoryRating
              isLinkCategories
              isLive
              isTranscoding
              geoipbloking
            }
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        showToast(this.$t('success'), 1, this)
        setClientNode()
      })
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
  },
}

</script>

<style lang="scss">
#platform-settings-transcoding .col-form-label {
  font-size: inherit;
  line-height: 1.45;
}
</style>
