<template>
  <b-overlay
    variant="transparent"
    :show="showLoading"
  >
    <b-tab
      id="s3"
      title-item-class="w-25"
    >
      <template #title>
        <b-card
          :img-src="require('@/assets/images/backend/transcoding/bitmovin.png')"
          overlay
        />
      </template>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            ACTUALMENTE NO SE PUEDE MODIFICAR LA CONFIGURACIÓN DE BITMOVIN
          </b-col>
          <b-col style="text-align: end;">
            <b-button
              type="submit"
              disabled
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-tab>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BCard,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BForm,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLoading: false,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.getBitmovinPlatformData()
  },
  methods: {
    getBitmovinPlatformData() {
      const userData = getUserData()
      const query = `
          query {
            client(id: "${userData.profile.client.id}"){
              id
            }
          }
        `
      axios.post('', { query }).then(() => {
        // TODO: SE DEJA PARA COMPLETAR CON LOS CAMPOS NECESARIOS PARA BITMOVIN
      })
    },
    onSubmit(event) {
      this.showLoading = true
      event.preventDefault()
      const userData = getUserData()
      // TODO: COMPLETAR CON LOS CAMPOS NECESARIOS PARA BITMOVIN
      const query = `
          mutation($id: ID!){
            updateClient(id: $id, input: {
              transcodingType: BTM,
            }){
              client {
                id
                transcodingType
              }
            }
          }
        `
      const variables = {
        id: userData.profile.client.id,
      }
      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(() => {
          showToast(this.$t('transcoding.transcodingChangeData'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('transcoding.transcodingErrorChangeData'), 2, this)
        })
      this.showLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~@core/scss/base/bootstrap-extended/include";
  @import "~@core/scss/base/components/variables-dark";

  .nav-link .card {
    background-color: #f6f6f6 !important;
  }

  .nav-link.active .card {
    background-image: linear-gradient(47deg, #7367f0, #9e95f5) !important;
  }
</style>
